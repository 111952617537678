import { styled } from 'stitches.config';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { useEffect, useState } from 'react';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import Input from 'Atoms/Input/Input';
import Button from 'Atoms/Buttons/Button';
import UpdateUserProfileRequest from 'Models/User/UpdateUserProfileRequest.interface';
import {
  RequestStatus,
  UpdatePersonalInformation,
  UpdateProfile,
} from '../Account';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import UpdateUserPersonalInformationRequest from 'Models/User/UpdateUserPersonalInformationRequest.interface';
import OrderAddressModel from 'Models/Order/OrderAddressModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { StyledDivider } from '../AccountPage';
import FormTitle from 'Atoms/FormTitle/FormTitle';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';

const AccountDetailsTab = () => {
  const { user, reload } = useUserStateData();
  const { languageRoute, marketCountryThreeLetterCountryCode } =
    useAppSettingsData();
  const { checkoutLabels, validationLabels, accountLabels, commonLabels } =
    useTranslationData();
  const {
    shippingAddress: currentPageShippingAddress,
    billingAddress: currentPageBillingAddress,
  } = useCurrentPage<CheckoutPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const [isEditableProfile, setIsEditableProfile] = useState<boolean>(false);
  const [isEditablePersonalData, setIsEditablePersonalData] =
    useState<boolean>(false);
  const [validFormProfile, setValidFormProfile] = useState<boolean>(false);
  const [validFormPersonalData, setValidFormPersonalData] =
    useState<boolean>(false);
  const [saveProfileStatus, setSaveProfileStatus] = useState<RequestStatus>('');
  useState<string>('');
  const [savePersonalDataStatus, setSavePersonalDataStatus] =
    useState<RequestStatus>('');
  useState<string>('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>('');

  // Profile
  const [email, setEmail] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  // PersonalData
  const [shippingFirstName, setShippingFirstName] = useState<string>('');
  const [shippingLastName, setShippingLastName] = useState<string>('');
  const [shippingAddress, setShippingAddress] = useState<string>('');
  const [shippingPostalCode, setShippingPostalCode] = useState<string>('');
  const [shippingCity, setShippingCity] = useState<string>('');

  const [useSameShippingAddress, setUseSameShippingAddress] =
    useState<boolean>(true);

  const [billingFirstName, setBillingFirstName] = useState<string>('');
  const [billingLastName, setBillingLastName] = useState<string>('');
  const [billingAddress, setBillingAddress] = useState<string>('');
  const [billingPostalCode, setBillingPostalCode] = useState<string>('');
  const [billingCity, setBillingCity] = useState<string>('');

  const onInputsValid = () => {
    setValidFormProfile(true);
  };

  const onInputsInvalid = () => {
    setValidFormProfile(false);
  };

  const onInputsValidPersonalData = () => {
    setValidFormPersonalData(true);
  };

  const onInputsInvalidPersonalData = () => {
    setValidFormPersonalData(false);
  };

  const isSameBillingAndShippAdr =
    JSON.stringify(currentPageShippingAddress) ===
    JSON.stringify(currentPageBillingAddress);

  useEffect(() => {
    if (saveProfileStatus === 'error')
      setTimeout(() => setSaveProfileStatus(''), 5000);
    if (savePersonalDataStatus === 'error')
      setTimeout(() => setSavePersonalDataStatus(''), 5000);

    if (
      saveProfileStatus === 'success' ||
      savePersonalDataStatus === 'success'
    ) {
      reload();
    }
  }, [saveProfileStatus, savePersonalDataStatus, reload]);

  useEffect(() => {
    setEmail(user.email);
    setPhoneNumber(user.phoneNumber);

    setShippingFirstName(currentPageShippingAddress?.firstName);
    setShippingLastName(currentPageShippingAddress?.lastName);
    setShippingAddress(currentPageShippingAddress?.address);
    setShippingPostalCode(currentPageShippingAddress?.postalCode);
    setShippingCity(currentPageShippingAddress?.city);

    setBillingFirstName(currentPageBillingAddress?.firstName);
    setBillingLastName(currentPageBillingAddress?.lastName);
    setBillingAddress(currentPageBillingAddress?.address);
    setBillingPostalCode(currentPageBillingAddress?.postalCode);
    setBillingCity(currentPageBillingAddress?.city);

    if (!isSameBillingAndShippAdr) {
      setUseSameShippingAddress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentPageBillingAddress, currentPageBillingAddress]);

  const onClickCancelOrEditProfile = () => {
    setPasswordErrorMsg('');
    if (isEditableProfile) {
      setPhoneNumber(user.phoneNumber);
    }
    setIsEditableProfile(!isEditableProfile);
  };

  const onClickSaveProfile = () => {
    const saveProfile = () => {
      if (newPassword === confirmNewPassword) {
        UpdateProfile(
          {
            password: newPassword,
            passwordConfirmation: confirmNewPassword,
            phoneNumber: phoneNumber,
          } as UpdateUserProfileRequest,
          languageRoute,
          setSaveProfileStatus,
          () => {}
        );
      }
    };

    setPasswordErrorMsg('');
    if (isEditableProfile) {
      if (newPassword === confirmNewPassword) {
        saveProfile();
        setIsEditableProfile(!isEditableProfile);
      } else {
        setPasswordErrorMsg(validationLabels.confirmPasswordMissmatch);
      }
    } else {
      setIsEditableProfile(false);
    }
  };

  const onClickCancelOrEditPersonalData = () => {
    setPasswordErrorMsg('');
    if (isEditablePersonalData) {
      // If we are in edit mode, and clicks cancel, we reset the input form.
      setShippingFirstName(currentPageShippingAddress?.firstName);
      setShippingLastName(currentPageShippingAddress?.lastName);
      setShippingAddress(currentPageShippingAddress?.address);
      setShippingPostalCode(currentPageShippingAddress?.postalCode);
      setShippingCity(currentPageShippingAddress?.city);

      setBillingFirstName(currentPageBillingAddress?.firstName);
      setBillingLastName(currentPageBillingAddress?.lastName);
      setBillingAddress(currentPageBillingAddress?.address);
      setBillingPostalCode(currentPageBillingAddress?.postalCode);
      setBillingCity(currentPageBillingAddress?.city);

      if (!isSameBillingAndShippAdr) {
        setUseSameShippingAddress(false);
      }
    }
    setIsEditablePersonalData(!isEditablePersonalData);
  };

  const onClickSavePersonalData = () => {
    const savePersonalData = () => {
      const tempBillingAddress = {
        address: billingAddress,
        city: billingCity,
        countryName: marketCountryThreeLetterCountryCode, //This code must ISO 3 letter code.
        email: '',
        firstName: billingFirstName,
        id: '',
        lastName: billingLastName,
        phoneNumber: '', //No need to pass phoneNumber here since its stored on the contact, not the address.
        postalCode: billingPostalCode,
      } as OrderAddressModel;

      const tempShippingAddress = {
        address: shippingAddress,
        city: shippingCity,
        countryName: marketCountryThreeLetterCountryCode, //This code must ISO 3 letter code.
        email: '',
        firstName: shippingFirstName,
        id: '',
        lastName: shippingLastName,
        phoneNumber: '', //No need to pass phoneNumber here since its stored on the contact, not the address.
        postalCode: shippingPostalCode,
      } as OrderAddressModel;

      let request: UpdateUserPersonalInformationRequest = {
        firstName: shippingFirstName,
        lastName: shippingLastName,
        billingAddress: tempBillingAddress,
        shippingAddress: tempShippingAddress,
      };

      if (useSameShippingAddress) {
        request.billingAddress = request.shippingAddress;
      }

      UpdatePersonalInformation(
        request,
        languageRoute,
        setSavePersonalDataStatus,
        () => {}
      );
    };

    setPasswordErrorMsg('');
    if (isEditablePersonalData) {
      if (newPassword === confirmNewPassword) {
        savePersonalData();
        setIsEditablePersonalData(!isEditablePersonalData);
      }
      if (newPassword !== confirmNewPassword) {
        setPasswordErrorMsg(validationLabels.confirmPasswordMissmatch);
      }
    } else {
      setIsEditablePersonalData(false);
    }
  };

  // This is used to separate the funtionallties  between view inputfields and editable inputfields
  const RenderViewComponentsPersonalData = () => {
    return (
      <LeftPaneContainer>
        <InputValidation onInputsValid={() => {}} onInputsInvalid={() => {}}>
          <FormInputContainer>
            <FormTitleWrapper>
              <FormTitle>{checkoutLabels.contactInformation}</FormTitle>
              {user.isLoggedWithBankId && (
                <>
                  <BulletPoint
                    backgroundColor="secondary"
                    text={accountLabels.connectedWithBankID}
                    css={{ fs: 6 }}
                    textCSS={{ alignItems: 'baseline' }}
                  />
                </>
              )}
            </FormTitleWrapper>

            <>
              <Column>
                <NameWrapper>
                  <Input
                    title={checkoutLabels.name}
                    placeholder={checkoutLabels.name}
                    onChange={setShippingFirstName}
                    name="Shipping Name"
                    defaultValue={shippingFirstName}
                    disabled={true}
                  />
                </NameWrapper>
                <NameWrapper>
                  <Input
                    title={checkoutLabels.lastName}
                    placeholder={checkoutLabels.lastName}
                    onChange={setShippingLastName}
                    name="Shipping Last Name"
                    defaultValue={shippingLastName}
                    disabled={true}
                  />
                </NameWrapper>
              </Column>
              <Input
                title={checkoutLabels.address}
                placeholder={checkoutLabels.address}
                onChange={setShippingAddress}
                name="Shipping Address"
                defaultValue={shippingAddress}
                disabled={true}
              />
              <Column>
                <BillingPostalCodeWrapper>
                  <Input
                    title={checkoutLabels.zipCode}
                    placeholder={checkoutLabels.zipCode}
                    onChange={setShippingPostalCode}
                    name="Shipping PostalCode"
                    defaultValue={shippingPostalCode}
                    disabled={true}
                  />
                </BillingPostalCodeWrapper>
                <BillingCityWrapper>
                  <Input
                    title={checkoutLabels.city}
                    placeholder={checkoutLabels.city}
                    onChange={setShippingCity}
                    name="Shipping City"
                    defaultValue={shippingCity}
                    disabled={true}
                  />
                </BillingCityWrapper>
              </Column>
            </>
          </FormInputContainer>

          <Checkbox
            text={checkoutLabels.alternativeShippingAddress}
            onCheck={() => {}}
            isChecked={
              JSON.stringify(currentPageShippingAddress) ===
              JSON.stringify(currentPageBillingAddress)
            }
            id="alternative-shipping-address"
          />

          {!isSameBillingAndShippAdr && (
            <FormInputContainer>
              <>
                <Column css={{ marginTop: '24px' }}>
                  <NameWrapper>
                    <Input
                      title={checkoutLabels.name}
                      placeholder={checkoutLabels.name}
                      onChange={setBillingFirstName}
                      name="Billing Name"
                      defaultValue={billingFirstName}
                      disabled={true}
                    />
                  </NameWrapper>
                  <NameWrapper>
                    <Input
                      title={checkoutLabels.lastName}
                      placeholder={checkoutLabels.lastName}
                      onChange={setBillingLastName}
                      name="Billing Last Name"
                      disabled={true}
                      defaultValue={billingLastName}
                      inputBackground="primary5"
                    />
                  </NameWrapper>
                </Column>
                <Input
                  title={checkoutLabels.address}
                  placeholder={checkoutLabels.address}
                  onChange={setBillingAddress}
                  name="Billing Address"
                  disabled={true}
                  defaultValue={billingAddress}
                  inputBackground="primary5"
                />
                <Column>
                  <BillingPostalCodeWrapper>
                    <Input
                      title={checkoutLabels.zipCode}
                      placeholder={checkoutLabels.zipCode}
                      onChange={setBillingPostalCode}
                      name="Billing PostalCode"
                      autoComplete="postal-code"
                      disabled={true}
                      defaultValue={billingPostalCode}
                      inputBackground="primary5"
                    />
                  </BillingPostalCodeWrapper>
                  <BillingCityWrapper>
                    <Input
                      title={checkoutLabels.city}
                      placeholder={checkoutLabels.city}
                      onChange={setBillingCity}
                      name="Billing City"
                      disabled={true}
                      defaultValue={billingCity}
                      inputBackground="primary5"
                    />
                  </BillingCityWrapper>
                </Column>
              </>
            </FormInputContainer>
          )}
        </InputValidation>
        {!isDesktop && (
          <StyledCancelOrEditButton>
            <Button
              type={'secondaryDark'}
              onClick={onClickCancelOrEditPersonalData}
              size={'s'}
              fullWidth
            >
              {commonLabels.edit}
            </Button>
          </StyledCancelOrEditButton>
        )}
      </LeftPaneContainer>
    );
  };

  return (
    <AccountDetailsTabContainer>
      {saveProfileStatus && (
        <ChangesHasBeenMadeText status={saveProfileStatus}>
          {saveProfileStatus === 'error' ? accountLabels.changesSaveFailed : ''}
        </ChangesHasBeenMadeText>
      )}
      <MyProfileContainer>
        <LeftPaneContainer>
          <FormInputContainer>
            <FormTitle>{accountLabels.myProfile}</FormTitle>
            <InputValidation
              onInputsValid={() => {}}
              onInputsInvalid={() => {}}
            >
              <Input
                title={checkoutLabels.email}
                placeholder={''}
                onChange={setEmail}
                name="Email"
                defaultValue={email}
                disabled
              />
              {!isEditableProfile && (
                <>
                  <Input
                    title={checkoutLabels.phoneNumber}
                    placeholder={checkoutLabels.phoneNumber}
                    name="Template Phone Number"
                    defaultValue={phoneNumber}
                    disabled={!isEditableProfile}
                    inputBackground="primary5"
                  />
                  <Input
                    title={accountLabels.password}
                    type="text"
                    name="TemplatePassword"
                    placeholder={accountLabels.password}
                    defaultValue={'********'}
                    disabled={!isEditableProfile}
                    inputBackground="primary5"
                  />
                  {!isDesktop && (
                    <StyledCancelOrEditButton>
                      <Button
                        fullWidth
                        type={'secondaryDark'}
                        onClick={onClickCancelOrEditProfile}
                        size={'s'}
                      >
                        {isEditableProfile
                          ? commonLabels.abort
                          : commonLabels.edit}
                      </Button>
                    </StyledCancelOrEditButton>
                  )}
                </>
              )}
            </InputValidation>
            {isEditableProfile && (
              <>
                <InputValidation
                  onInputsValid={onInputsValid}
                  onInputsInvalid={onInputsInvalid}
                >
                  <Input
                    title={checkoutLabels.phoneNumber}
                    placeholder={checkoutLabels.phoneNumber}
                    onChange={setPhoneNumber}
                    name="Phone Number"
                    validation={{
                      ...accountFormValidation.phoneNumber,
                      errorMessage: validationLabels.invalidPhoneNumber,
                    }}
                    autoComplete="tel"
                    defaultValue={phoneNumber}
                    disabled={!isEditableProfile}
                    inputBackground="primary5"
                  />
                  <input
                    type="text"
                    id="EmptyComponentToTrickAutofill"
                    name="EmptyComponentToTrickAutofill"
                    value="none"
                    readOnly
                    style={{
                      position: 'absolute',
                      padding: '0px',
                      height: 0,
                      width: 0,
                    }}
                  />
                  <Input
                    title={accountLabels.newPassword}
                    type="password"
                    name="NewPassword"
                    placeholder={accountLabels.newPassword}
                    onChange={setNewPassword}
                    validation={{
                      pattern:
                        newPassword !== ''
                          ? accountFormValidation.password.pattern
                          : new RegExp(''),
                      errorMessage: validationLabels.invalidPassword,
                    }}
                    defaultValue={''}
                    disabled={!isEditableProfile}
                    inputBackground="primary5"
                  />
                  <Input
                    title={accountLabels.confirmNewPassword}
                    type="password"
                    name="ConfirmNewPassword"
                    placeholder={accountLabels.confirmNewPassword}
                    onChange={setConfirmNewPassword}
                    validation={{
                      pattern:
                        confirmNewPassword !== ''
                          ? accountFormValidation.password.pattern
                          : new RegExp(''),
                      errorMessage: validationLabels.invalidPassword,
                    }}
                    defaultValue={''}
                    disabled={!isEditableProfile}
                    inputBackground="primary5"
                  />
                </InputValidation>
                {passwordErrorMsg && <ErrorText>{passwordErrorMsg}</ErrorText>}
                {!isDesktop && (
                  <Column css={{ marginTop: '32px' }}>
                    <StyledMobileCancelButton>
                      <Button
                        type={'secondaryDark'}
                        onClick={onClickCancelOrEditProfile}
                        size={'s'}
                        fullWidth
                      >
                        {commonLabels.abort}
                      </Button>
                    </StyledMobileCancelButton>
                    <StyledSaveButton>
                      <Button
                        type={'primary'}
                        fullWidth
                        size={'s'}
                        onClick={onClickSaveProfile}
                        disabled={!validFormProfile}
                      >
                        {commonLabels.save}
                      </Button>
                    </StyledSaveButton>
                  </Column>
                )}

                {isDesktop && (
                  <Button
                    css={{ marginTop: '42px', marginLeft: ' auto' }}
                    type={'primary'}
                    size={'s'}
                    onClick={onClickSaveProfile}
                    disabled={!validFormProfile}
                  >
                    {commonLabels.save}
                  </Button>
                )}
              </>
            )}
          </FormInputContainer>
        </LeftPaneContainer>
        {isDesktop && (
          <RightPaneContainer>
            <StyledCancelOrEditButton>
              <Button
                type={'secondaryDark'}
                onClick={onClickCancelOrEditProfile}
                size={'s'}
              >
                {isEditableProfile ? commonLabels.abort : commonLabels.edit}
              </Button>
            </StyledCancelOrEditButton>
          </RightPaneContainer>
        )}
      </MyProfileContainer>

      <StyledDivider />

      {savePersonalDataStatus && (
        <ChangesHasBeenMadeText status={savePersonalDataStatus}>
          {savePersonalDataStatus === 'error'
            ? accountLabels.changesSaveFailed
            : ''}
        </ChangesHasBeenMadeText>
      )}
      <MyPersonalInfoContainer>
        {!isEditablePersonalData && RenderViewComponentsPersonalData()}
        {isEditablePersonalData && (
          <LeftPaneContainer>
            <InputValidation
              onInputsValid={onInputsValidPersonalData}
              onInputsInvalid={onInputsInvalidPersonalData}
            >
              <FormInputContainer>
                <FormTitle>{checkoutLabels.contactInformation}</FormTitle>
                <>
                  <Column>
                    <NameWrapper>
                      <Input
                        title={checkoutLabels.name}
                        placeholder={checkoutLabels.name}
                        onChange={setShippingFirstName}
                        name="Shipping Name"
                        validation={{
                          required: true,
                          minLength: 1,
                          maxLength: 40,
                          errorMessage: validationLabels.invalidName,
                        }}
                        autoComplete="given-name"
                        defaultValue={shippingFirstName}
                        disabled={!isEditablePersonalData}
                        inputBackground="primary5"
                      />
                    </NameWrapper>
                    <NameWrapper>
                      <Input
                        title={checkoutLabels.lastName}
                        placeholder={checkoutLabels.lastName}
                        onChange={setShippingLastName}
                        name="Shipping Last Name"
                        validation={{
                          minLength: 1,
                          maxLength: 40,
                          errorMessage: validationLabels.invalidLastName,
                        }}
                        autoComplete="family-name"
                        defaultValue={shippingLastName}
                        disabled={!isEditablePersonalData}
                        inputBackground="primary5"
                      />
                    </NameWrapper>
                  </Column>
                  <Input
                    title={checkoutLabels.address}
                    placeholder={checkoutLabels.address}
                    onChange={setShippingAddress}
                    name="Shipping Address"
                    validation={{
                      minLength: 1,
                      maxLength: 60,
                      errorMessage: validationLabels.invalidAddress,
                    }}
                    autoComplete="street-address"
                    defaultValue={shippingAddress}
                    disabled={!isEditablePersonalData}
                    inputBackground="primary5"
                  />
                  <Column>
                    <BillingPostalCodeWrapper>
                      <Input
                        title={checkoutLabels.zipCode}
                        placeholder={checkoutLabels.zipCode}
                        onChange={setShippingPostalCode}
                        name="Shipping PostalCode"
                        validation={{
                          minLength: 1,
                          maxLength: 10,
                          errorMessage: validationLabels.invalidZipCode,
                        }}
                        autoComplete="postal-code"
                        defaultValue={shippingPostalCode}
                        disabled={!isEditablePersonalData}
                        inputBackground="primary5"
                      />
                    </BillingPostalCodeWrapper>
                    <BillingCityWrapper>
                      <Input
                        title={checkoutLabels.city}
                        placeholder={checkoutLabels.city}
                        onChange={setShippingCity}
                        name="Shipping City"
                        validation={{
                          minLength: 1,
                          maxLength: 40,
                          errorMessage: validationLabels.invalidCity,
                        }}
                        autoComplete="home city"
                        defaultValue={shippingCity}
                        disabled={!isEditablePersonalData}
                        inputBackground="primary5"
                      />
                    </BillingCityWrapper>
                  </Column>
                </>
              </FormInputContainer>

              <Checkbox
                text={checkoutLabels.alternativeShippingAddress}
                onCheck={() =>
                  setUseSameShippingAddress(!useSameShippingAddress)
                }
                isChecked={useSameShippingAddress}
                id="alternative-shipping-address"
              />

              {!useSameShippingAddress && (
                <FormInputContainer>
                  <>
                    <Column css={{ marginTop: '24px' }}>
                      <NameWrapper>
                        <Input
                          title={checkoutLabels.name}
                          placeholder={checkoutLabels.name}
                          onChange={setBillingFirstName}
                          name="Billing Name"
                          validation={{
                            active: !useSameShippingAddress,
                            minLength: 1,
                            maxLength: 40,
                            errorMessage: validationLabels.invalidName,
                          }}
                          autoComplete="given-name"
                          defaultValue={billingFirstName}
                          disabled={!isEditablePersonalData}
                          inputBackground="primary5"
                        />
                      </NameWrapper>
                      <NameWrapper>
                        <Input
                          title={checkoutLabels.lastName}
                          placeholder={checkoutLabels.lastName}
                          onChange={setBillingLastName}
                          name="Billing Last Name"
                          validation={{
                            active: !useSameShippingAddress,
                            minLength: 1,
                            maxLength: 40,
                            errorMessage: validationLabels.invalidLastName,
                          }}
                          autoComplete="family-name"
                          disabled={!isEditablePersonalData}
                          defaultValue={billingLastName}
                          inputBackground="primary5"
                        />
                      </NameWrapper>
                    </Column>
                    <Input
                      title={checkoutLabels.address}
                      placeholder={checkoutLabels.address}
                      onChange={setBillingAddress}
                      name="Billing Address"
                      validation={{
                        active: !useSameShippingAddress,
                        minLength: 1,
                        maxLength: 60,
                        errorMessage: validationLabels.invalidAddress,
                      }}
                      autoComplete="street-address"
                      disabled={!isEditablePersonalData}
                      defaultValue={billingAddress}
                      inputBackground="primary5"
                    />
                    <Column>
                      <BillingPostalCodeWrapper>
                        <Input
                          title={checkoutLabels.zipCode}
                          placeholder={checkoutLabels.zipCode}
                          onChange={setBillingPostalCode}
                          name="Billing PostalCode"
                          validation={{
                            active: !useSameShippingAddress,
                            minLength: 1,
                            maxLength: 10,
                            errorMessage: validationLabels.invalidZipCode,
                          }}
                          autoComplete="postal-code"
                          disabled={!isEditablePersonalData}
                          defaultValue={billingPostalCode}
                          inputBackground="primary5"
                        />
                      </BillingPostalCodeWrapper>
                      <BillingCityWrapper>
                        <Input
                          title={checkoutLabels.city}
                          placeholder={checkoutLabels.city}
                          onChange={setBillingCity}
                          name="Billing City"
                          validation={{
                            active: !useSameShippingAddress,
                            minLength: 1,
                            maxLength: 40,
                            errorMessage: validationLabels.invalidCity,
                          }}
                          autoComplete="home city"
                          disabled={!isEditablePersonalData}
                          defaultValue={billingCity}
                          inputBackground="primary5"
                        />
                      </BillingCityWrapper>
                    </Column>
                  </>
                </FormInputContainer>
              )}

              {isEditablePersonalData && isDesktop && (
                <Button
                  css={{ marginTop: '42px', marginLeft: ' auto' }}
                  type={'primary'}
                  size={'s'}
                  onClick={onClickSavePersonalData}
                  disabled={!validFormPersonalData}
                >
                  {commonLabels.save}
                </Button>
              )}
              {!isDesktop && (
                <Column css={{ marginTop: '32px' }}>
                  <StyledMobileCancelButton>
                    <Button
                      type={'secondaryDark'}
                      onClick={onClickCancelOrEditPersonalData}
                      size={'s'}
                      fullWidth
                    >
                      {commonLabels.abort}
                    </Button>
                  </StyledMobileCancelButton>
                  <StyledSaveButton>
                    <Button
                      type={'primary'}
                      fullWidth
                      size={'s'}
                      onClick={onClickSavePersonalData}
                      disabled={!validFormPersonalData}
                    >
                      {commonLabels.save}
                    </Button>
                  </StyledSaveButton>
                </Column>
              )}
            </InputValidation>
          </LeftPaneContainer>
        )}

        {isDesktop && (
          <RightPaneContainer>
            <StyledCancelOrEditButton>
              <Button
                type={'secondaryDark'}
                onClick={onClickCancelOrEditPersonalData}
                size={'s'}
              >
                {isEditablePersonalData
                  ? commonLabels.abort
                  : commonLabels.edit}
              </Button>
            </StyledCancelOrEditButton>
          </RightPaneContainer>
        )}
      </MyPersonalInfoContainer>
    </AccountDetailsTabContainer>
  );
};

export default AccountDetailsTab;

const AccountDetailsTabContainer = styled('div', {
  height: '100%',
  overflowY: 'auto',
  backgroundColor: '$primary5',
});

const ChangesHasBeenMadeText = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    status: {
      success: {
        color: '$JE68GreenPrimary',
      },
      error: {
        color: '$errorColor',
      },
      loading: {},
    },
  },
  defaultVariants: {
    status: 'success',
  },
});

const Column = styled('div', {
  display: 'flex',
  columnGap: '16px',
});

const BillingPostalCodeWrapper = styled('div', {
  width: '40%',
});

const NameWrapper = styled('div', {
  width: '50%',
});

const BillingCityWrapper = styled('div', {
  width: '60%',
});

const MyProfileContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const MyPersonalInfoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftPaneContainer = styled('div', {
  width: ' 100%',
  '@mediaMinLarge': {
    minWidth: '495px',
    maxWidth: '495px',
  },
});

const RightPaneContainer = styled('div', {
  marginTop: '32px',
  marginLeft: 'auto',
});

const StyledCancelOrEditButton = styled('div', {
  marginTop: '32px',
  marginLeft: 'auto',
});

const StyledSaveButton = styled('div', {
  width: '50%',
});

const StyledMobileCancelButton = styled('div', {
  width: '50%',
});

const FormInputContainer = styled('div', {
  mb: 6,
});

const ErrorText = styled('div', {
  fontFamily: '$fontSecondary400',
  letterSpacing: '$ls0',
  fs: 6,
  lineHeight: '$lh133',
  color: '$errorColor',
  ml: '15px',
});

const FormTitleWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});
