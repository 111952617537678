import KexLink from 'Kex/KexLink';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import Price from 'Atoms/Price/Price';
import ProductImage from 'Atoms/Image/ProductImage';
import { styled } from 'stitches.config';
import ProductCardTitle from 'Atoms/ProductCardTitle/ProductCardTitle';

import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useProductImage from 'Shared/Hooks/useProductImage';
import { useMemo } from 'react';

type PropTypes = {
  product: VariationModel;
};

function OrderItemCard({ product }: PropTypes) {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const {
    to,
    image,
    name,
    totalNowPrice,
    totalWasPrice,
    wasPrice,
    nowPrice,
    isUsed,
    articleNumber,
  } = product;

  const { itemImage } = useProductImage(image, product.variationImages);

  const imageWithPreset = useMemo(() => {
    if (isMobile) {
      return image
        ? isUsed
          ? `${itemImage.src}?preset=small-product-card-used-mobile`
          : `${itemImage.src}?preset=small-product-card-mobile`
        : `${itemImage.src}?preset=small-product-card-mobile`;
    } else {
      return image
        ? isUsed
          ? `${itemImage.src}?preset=small-product-card-used-desktop`
          : `${itemImage.src}?preset=small-product-card-desktop`
        : `${itemImage.src}?preset=small-product-card-desktop`;
    }
  }, [itemImage.src, isUsed, image, isMobile]);

  return (
    <CartItemContainer>
      <KexLink css={{ mb: 0 }} href={to} underlined={false}>
        <ImageContainer>
          <ProductImage
            src={imageWithPreset}
            alt={itemImage?.alt}
            isUsed={isUsed}
          />
        </ImageContainer>
      </KexLink>
      <Details>
        <TopWrapper>
          <StyledKexLink href={to} underlined={false}>
            <ProductCardTitle title={name} />
            <ArticleNumber>{articleNumber}</ArticleNumber>
          </StyledKexLink>
          <Price
            friendlyWasPrice={totalWasPrice?.priceRoundedAsString}
            friendlyNowPrice={totalNowPrice?.priceRoundedAsString}
            wasPrice={wasPrice?.price}
            nowPrice={nowPrice?.price}
            direction="columnReverse"
            nowPriceSize={{ fs: isMobile ? 6 : 8 }}
            wasPriceSize={{ fs: 6 }}
          />
        </TopWrapper>
      </Details>
    </CartItemContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  maxW: '80%',
  fontFamily: '$fontSecondary600',
  wordSpacing: 'unset',

  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const CartItemContainer = styled('div', {
  display: 'flex',
  position: 'relative',

  '&:not(:last-child)': {
    py: 5,
    borderBottomWidth: '1px',
    borderBottomColor: '$checkoutPageBackgroundPrimary',
  },
  '&:last-child': {
    pt: 5,
    mb: 6,
  },
  variants: {
    isMiniCart: {
      true: {
        '&:not(:last-child)': {
          py: 5,
          borderBottomWidth: '1px',
          borderBottomColor: '$primary2',
        },
      },
    },
  },
});

const ImageContainer = styled('div', {
  width: 96,
  height: 72,
  mr: 4,
  '@mediaMinLarge': {
    width: 104,
    height: 80,
  },
});

const TopWrapper = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@mediaMinLarge': {
    flexDirection: 'row',
  },
  h: '100%',
});

const Details = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const ArticleNumber = styled('span', {
  fs: 6,
  lineHeight: '$lh133',
});

export default OrderItemCard;
