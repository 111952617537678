export async function FetchPagination(
  paginationType: string,
  currentPage: number,
  numberOfRecords: number,
  filter: {},
  onResult: (x: any) => void, // TODO: https://stackoverflow.com/a/49563070 - this could be a better solution. Let's wait for other implementations
  languageRoute: string
) {
  const body = JSON.stringify({
    startIndex: currentPage * numberOfRecords,
    numberOfRecords,
    returnTotalCount: true,
    paginationType,
    filter,
  });

  const res = await fetch(`/PaginationFilter?culture=${languageRoute}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  if (res.status === 200) {
    const data = await res.json();
    if (data) {
      onResult(data);
      return;
    }
  }
}
