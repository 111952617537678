import { useEffect, useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import TabButton from 'Atoms/Buttons/TabButton';
import TabButtonGroup from 'Molecules/ButtonGroups/TabButtonGroup';
import AccountGarageTab from './AccountTab/AccountGarage/AccountGarageTab';
import AccountDetailsTab from './AccountTab/AccountDetailsTab';
import AccountOrdersTab from './AccountTab/AccountOrdersTab';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import AccountPageModel from 'Models/Pages/AccountPage/AccountPageModel.interface';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { SignOut } from './Account';
import Button from 'Atoms/Buttons/Button';
import useMedia from 'Shared/Hooks/useMedia';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

const GARAGE = 'garage';
const ACCOUNT_DETAILS = 'details';
const ORDERS = 'orders';

function AccountPage() {
  const [currentTab, setCurrentTab] = useState<string>(ACCOUNT_DETAILS);
  const { accountLabels } = useTranslationData();
  const { user, accountDispatch } = useUserStateData();
  const authenticated = user.authenticated;
  const { heroImage } = useCurrentPage<AccountPageModel>();
  const { languageRoute } = useAppSettingsData();
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  useEffect(() => {
    if (!authenticated) {
      accountDispatch({ type: 'toggle' });
    }
  }, [accountDispatch, authenticated]);

  const renderTabContent = () => {
    switch (currentTab) {
      case GARAGE:
        return <AccountGarageTab />;
      case ACCOUNT_DETAILS:
        return <AccountDetailsTab key={JSON.stringify(user)} />;
      case ORDERS:
        return <AccountOrdersTab />;
      default:
        <></>;
    }
  };

  return !!authenticated ? (
    <>
      <SmallHeroBlock image={heroImage} />
      <PageContainer>
        <ContentContainer noPadding>
          <StyledTabContainer>
            <TabButtonGroup>
              <TabButton
                active={currentTab === GARAGE}
                onClick={() => setCurrentTab(GARAGE)}
                css={{ p: 4 }}
              >
                {accountLabels.myGarage}
              </TabButton>
              <TabButton
                active={currentTab === ACCOUNT_DETAILS}
                onClick={() => setCurrentTab(ACCOUNT_DETAILS)}
                css={{ p: 4 }}
              >
                {accountLabels.myDetails}
              </TabButton>
              <TabButton
                active={currentTab === ORDERS}
                onClick={() => setCurrentTab(ORDERS)}
                css={{ p: 4 }}
              >
                {accountLabels.orderHistory}
              </TabButton>
            </TabButtonGroup>
            <StyledTabContent>
              {renderTabContent()}
              <StyledDivider />
              <Button
                fullWidth={isMobile}
                css={{ marginLeft: 'auto' }}
                type={'secondaryDark'}
                disabled={false}
                onClick={() => {
                  SignOut(languageRoute);
                }}
                size={'s'}
              >
                {accountLabels.signOut}
              </Button>
            </StyledTabContent>
          </StyledTabContainer>
        </ContentContainer>
      </PageContainer>
    </>
  ) : (
    <></>
  );
}

export default AccountPage;

const PageContainer = styled('div', {
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '$primary5',
  position: 'relative',
  top: -92,
  p: 4,
  '@mediaMinLarge': {
    p: 8,
    maxWidth: '864px',
    backgroundColor: '$primary5',
    mb: 30,
  },
});

const StyledTabContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    px: 0,
    backgroundColor: '$primary5',
  },
});

const StyledTabContent = styled('div', {
  color: '$secondary2',
  py: 8,
});

export const StyledDivider = styled('div', {
  marginTop: '48px',
  marginBottom: '48px',
  width: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$primary1',
});
