import ArrowButton from 'Atoms/Buttons/ArrowButton';
import React from 'react';
import { useEffect, useState } from 'react';
import { styled } from 'stitches.config';

type PropTypes = {
  numberOfRecords: number;
  totalRecords: number;
  onPageChange: (x: any) => void;
  currentPage?: number;
};

function PaginationControls({
  totalRecords,
  numberOfRecords,
  onPageChange,
  currentPage: currentPageProp,
}: PropTypes) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const totalAmountSteps = [
    ...Array(Math.ceil(totalRecords / numberOfRecords)),
  ];

  useEffect(() => {
    if (currentPageProp !== undefined) {
      setCurrentPage(currentPageProp);
    }
  }, [currentPageProp]);

  const updatePagination = (currentPage: number) => {
    onPageChange(currentPage);
    setCurrentPage(currentPage);
  };

  const nextPage = () => {
    updatePagination(currentPage + 1);
  };

  const prevPage = () => {
    updatePagination(currentPage - 1);
  };

  const goToPage = (index: number | string) => {
    if (typeof index === 'string') {
      index = parseInt(index);
    }
    updatePagination(index);
  };

  const renderPaginationSteps = () => {
    if (totalAmountSteps.length <= 1) return <React.Fragment />;
    return (
      <>
        {totalAmountSteps &&
          totalAmountSteps.map((_, index) => {
            return (
              <StyledStep
                isActive={index === currentPage}
                onClick={() => goToPage(index)}
                key={index}
              >
                {index + 1}
              </StyledStep>
            );
          })}
      </>
    );
  };

  return (
    <PaginationWrapper>
      {totalRecords ? (
        <>
          <ArrowButton
            onClick={prevPage}
            bgColor="primary"
            size="l"
            right={false}
            disabled={currentPage === 0}
          />

          <StepsWrapper>{renderPaginationSteps()}</StepsWrapper>

          <ArrowButton
            onClick={nextPage}
            bgColor="primary"
            size="l"
            right={true}
            disabled={currentPage >= totalAmountSteps.length - 1}
          />
        </>
      ) : null}
    </PaginationWrapper>
  );
}

const StyledStep = React.memo(
  ({
    isActive = false,
    children,
    ...restProps
  }: {
    isActive?: boolean;
    children: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>) => (
    <Step isActive={isActive} {...restProps}>
      {children}
    </Step>
  )
);

const PaginationWrapper = styled('div', {
  display: 'flex',
  mt: 8,
  width: '100%',
  justifyContent: 'space-between',
});

const StepsWrapper = styled('div', {
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
});

const Step = styled('span', {
  mx: 5,
  cursor: 'pointer',
  fs: 9,
  variants: { isActive: { true: { color: '$JE68GreenPrimary' } } },
});

export default React.memo(PaginationControls);
