import { ChevronIcon } from 'Atoms/Icons';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import OrderModel from 'Models/Order/OrderModel.interface';
import { useState } from 'react';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { animation, timings } from 'Theme/Settings/animation';
import OrderHistoryPriceSummary from './OrderHistoryDetails';
import OrderItemCard from './OrderItemCard';

type PropTypes = {
  label: string;
  order: OrderModel;
};

export function OrderItem({ label, order }: PropTypes) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [innerHeight, setInnerHeight] = useState<boolean>(false);

  const {
    accountLabels: { products },
  } = useTranslationData();

  const onToggle = () => {
    if (innerHeight) {
      setTimeout(() => setInnerHeight(!innerHeight), 300);
    } else {
      setInnerHeight(!innerHeight);
    }
    setOpen(!isOpen);
  };

  return (
    <>
      <TitleWrapper onClick={onToggle} isOpen={isOpen}>
        <CurrentValue>{order.orderDate} </CurrentValue>
        <CurrentValue>{label} </CurrentValue>
        <CurrentValue
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            g: 8,
          }}
        >
          {order.orderStatus}
          <ChevronIcon
            size="m"
            css={{
              transition: `transform ${timings.threeTenths} ${animation.timingFn}`,
              m: 0,
              transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
            }}
            color="secondary"
          />
        </CurrentValue>
      </TitleWrapper>
      <Row isOpen={innerHeight}>
        <td colSpan={3}>
          <InnerWrapper isOpen={isOpen} innerHeight={innerHeight}>
            <OrderHistoryPriceSummary order={order} />
            <HeaderWrapper>
              <H3 noMargin>{products}</H3>{' '}
            </HeaderWrapper>
            {order.orderItems
              .filter((item) => item)
              .map((item, i) => {
                return (
                  <ItemWrapper key={item.articleNumber}>
                    <OrderItemCard key={item.articleNumber} product={item} />
                  </ItemWrapper>
                );
              })}
          </InnerWrapper>
        </td>
      </Row>
    </>
  );
}

export default OrderItem;

const CurrentValue = styled('td', {
  color: '$dropdownText',
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh133',
  fs: 6,
  p: 4,
  margin: 'auto',
  '@mediaMinLarge': {
    lineHeight: '$lh15',
    fs: 8,
    py: 6,
    px: 4,
  },
});

const HeaderWrapper = styled('div', {
  mt: 12,
  mb: 1,
  '@mediaMinLarge': {
    mt: 8,
  },
});

const Row = styled('tr', {
  variants: {
    isOpen: {
      false: {
        visibility: 'hidden',
      },
      true: {
        borderBottomWidth: '1px',
        borderBottomColor: '$primary1',
      },
    },
  },
});

const ItemWrapper = styled('div', {
  '&:not(:last-child)': {
    borderBottomWidth: '1px',
    borderBottomColor: '$primary1',
  },
});

const TitleWrapper = styled('tr', {
  position: 'relative',
  color: '$collapsableText',
  py: 4,
  px: 6,
  justifyContent: 'space-between',
  fontFamily: '$fontPrimary400',
  alignItems: 'center',
  cursor: 'pointer',
  fs: 10,
  lh: '$lh133',
  borderBottomWidth: '1px',
  borderBottomColor: '$primary1',
  variants: {
    isOpen: {
      true: {
        '@mediaMinLarge': {
          backgroundColor: '$primary4',
        },
        borderWidth: '1px',
        borderColor: '$JE68GreenPrimary',
      },
    },
  },
  ls: '$lsn1',
});

const InnerWrapper = styled('div', {
  opacity: 0,
  maxHeight: 0,
  display: 'flex',
  columnSpan: '3',
  WebkitColomnSpan: 'all',
  flexDirection: 'column',
  overflowY: 'hidden',
  color: '$collapsableText',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'opacity',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
      },
    },
    innerHeight: {
      true: {
        maxHeight: 'unset',
        px: 4,
      },
    },
  },
});
