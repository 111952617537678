import { H3 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import OrderModel from 'Models/Order/OrderModel.interface';
import SingleSelectDropDown from 'Molecules/DropDowns/SingleSelectDropDown';
import OrderItem from 'Molecules/OrderItem/OrderItem';
import OrderTable from 'Molecules/OrderItem/Table/OrderTable';
import SearchInput from 'Organisms/QuickSearch/SearchInput';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFiveLatestYears } from 'Shared/Common/Helpers';
import { useDebounce } from 'Shared/Hooks/useDebounce';
import useMedia from 'Shared/Hooks/useMedia';
import PaginationControlls from 'Shared/PaginationFilter/PaginationControls';
import { FetchPagination } from 'Shared/PaginationFilter/PaginationFilter';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

type PaginationDataType = {
  paginationItems: OrderModel[];
  totalRecords: number;
};

type Year = {
  key: number;
  value: string;
};

const TAKE = 10;

const AccountOrdersTab = () => {
  const {
    accountLabels: {
      date,
      orderNumber,
      status,
      searchPlaceholder,
      noOrdersHeader,
      noOrdersBody,
    },
    commonLabels: { all },
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();
  const [filterQuery, setFilterQuery] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const queryDebounced = useDebounce(query, 500);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // key is not used, it's here to keep singleselectdropdown typing happy
  const [yearFilter, setYearFilter] = useState<Year>({ key: 0, value: all });
  const [yearValues, setYearValues] = useState<Year[]>([]);
  const [orderItems, setOrderItems] = useState<OrderModel[]>([]);
  const isMobile = useMedia(mediaQueryTypes.mediaMaxLarge);

  const onFetchResult = useCallback((data: PaginationDataType) => {
    setOrderItems(data.paginationItems);
    setTotalRecords(data.totalRecords);
    setIsLoading(false);
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchPagination = useCallback(
    (page?: number) => {
      FetchPagination(
        'OrderModel',
        page || 0,
        TAKE,
        { query: filterQuery, year: yearFilter.value },
        onFetchResult,
        languageRoute
      );
    },
    [languageRoute, filterQuery, yearFilter.value, onFetchResult]
  );

  useEffect(() => {
    fetchPagination();
  }, [fetchPagination]);

  useEffect(() => {
    setYearValues([{ key: 0, value: all }, ...getFiveLatestYears()]);
  }, [all]);

  useEffect(() => {
    setFilterQuery(queryDebounced);
    setIsLoading(true);
  }, [queryDebounced]);

  const onPageChange = useCallback(
    (page: number) => {
      fetchPagination(page);
    },
    [fetchPagination]
  );

  const setSelected = (_key: number, value: string) => {
    setYearFilter({ key: 0, value: value });
  };

  const showNoResult =
    orderItems.length < 1 &&
    !isLoading &&
    yearFilter.value === all &&
    filterQuery === '';

  return (
    <Root>
      {!showNoResult ? (
        <>
          <SearchInputContainer>
            <SearchInput
              doSearch={() => setFilterQuery(query)}
              setQuery={setQuery}
              query={query}
              css={{
                mx: 'auto',
                backgroundColor: '$primary5',
                h: isMobile ? 12 : 10,
              }}
              inputRef={inputRef}
              placeholder={searchPlaceholder}
            />
            <YearFilterContainer>
              <SingleSelectDropDown
                currentValue={yearFilter.value}
                handleSelect={(key, value) => setSelected(key, value)}
                values={yearValues}
              />
            </YearFilterContainer>
          </SearchInputContainer>
          <TableWrapper>
            <OrderTable
              headers={[date, orderNumber, status]}
              isLoading={isLoading}
            >
              {orderItems
                .filter((item) => item)
                .map((item) => {
                  return (
                    <OrderItem
                      label={item.orderNumber}
                      order={item}
                      key={item.orderNumber}
                    />
                  );
                })}
            </OrderTable>
          </TableWrapper>
          {Boolean(totalRecords) && (
            <PaginationControlls
              totalRecords={totalRecords}
              numberOfRecords={TAKE}
              onPageChange={onPageChange}
            />
          )}
        </>
      ) : (
        <NoOrdersWrapper>
          <H3>{noOrdersHeader}</H3>
          <Paragraph>{noOrdersBody}</Paragraph>
        </NoOrdersWrapper>
      )}
    </Root>
  );
};

const SearchInputContainer = styled('div', {
  my: 8,
  mx: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    flexDirection: 'row',
  },
});

const TableWrapper = styled('div', {
  '@mediaMaxLarge': {
    w: '100vw',
    px: 0,
    left: '50%',
    right: '50%',
    maxWidth: '100vw',
    position: 'relative',
    marginLeft: `-50vw`,
    marginRight: '-50vw',
  },
});

const Root = styled('div', {
  backgroundColor: '$primary5',
  pb: 12,
});

const NoOrdersWrapper = styled('div', {
  mt: 8,
  mb: 20,
  mx: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    textAlign: 'center',
    maxW: 75,
  },
});

const YearFilterContainer = styled('div', {
  mt: 8,
  '@mediaMinLarge': {
    mt: 0,
  },
});

export default AccountOrdersTab;
