import { CSS } from '@stitches/react';
import { ArrowIcon } from 'Atoms/Icons';
import { styled } from 'stitches.config';

type CloseButtonBaseType = {
  onClick: () => void;
  css?: CSS;
  size: 'xs' | 's' | 'm' | 'l';
  bgColor: 'primary' | 'secondary' | 'primaryLight';
  disabled: boolean;
  right: boolean;
};

function ArrowButton({
  onClick,
  css,
  size,
  bgColor = 'primary',
  disabled = false,
  right = false,
}: CloseButtonBaseType) {
  const iconColor = bgColor === 'primary' ? 'secondary' : 'secondaryDark';
  return (
    <CloseBtn
      css={css}
      onClick={onClick}
      size={size}
      disabled={disabled}
      right={right}
    >
      <CircleBackground bgColor={bgColor}>
        <ArrowIcon fillColor={iconColor} size="m" />
      </CircleBackground>
    </CloseBtn>
  );
}
export default ArrowButton;

const CloseBtn = styled('button', {
  flexShrink: 0,
  variants: {
    size: {
      xs: {
        wh: 2,
      },
      s: {
        wh: 4,
      },
      m: {
        wh: 6,
      },
      l: {
        wh: 12,
      },
    },
    disabled: {
      true: {
        visibility: 'hidden',
      },
    },
    right: {
      true: {
        transform: 'scaleX(-1)',
      },
    },
  },
});

const CircleBackground = styled('span', {
  wh: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  stroke: '$secondary2',
  variants: {
    bgColor: {
      primary: {
        backgroundColor: '$primary4',
        '&:hover': {
          backgroundColor: '$primary3',
        },
      },
      primaryLight: {
        backgroundColor: '$primary3',
        '&:hover': {
          backgroundColor: '$primary2',
        },
      },
      secondary: {
        backgroundColor: '$pureWhite',
        '&:hover': {
          backgroundColor: '$secondary1',
        },
      },
    },
  },
});
