import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import UserStateModel from '../../Models/App/UserStateModel.interface';
import { IS_PRODUCTION_ENV } from '../Configs/EnvConfig';
import FetchUserState from '../Fetchers/FetchUserState';

let hasMounted = false;

export default function useQueryUserState(
  initialData: UserStateModel
): [UserStateModel] {
  const [data, setData] = useState(initialData);
  const queryData = useSWR('/App/GetUserState', FetchUserState, {
    fallbackData: hasMounted ? undefined : initialData,
    revalidateOnFocus: IS_PRODUCTION_ENV,
  });

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateUserState() {
  const res = await fetch('/App/GetUserState');
  if (res.ok) {
    const { userState } = await res.json();
    mutate('/App/GetUserState', userState, false);
  }
}
