import { styled } from 'stitches.config';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import Divider from 'Atoms/Divider/Divider';
import OrderModel from 'Models/Order/OrderModel.interface';

type PropType = {
  order: OrderModel;
};

function OrderHistoryDetails({ order }: PropType) {
  const {
    shippingAddress,
    numberOfItems,
    paymentMethods,
    shippingMethod,
    totalFreightPrice,
    totalPrice,
    totalPriceForItems,
    totalDiscount,
  } = order;
  const {
    checkoutLabels: { total },
    accountLabels: {
      address,
      deliveryMethod,
      paymentMethod: paymentMethodLabel,
      amount,
      shippingCost,
      totalDiscountText,
    },
  } = useTranslationData();

  return (
    <Info>
      <div>
        <SmallHeader>{address}</SmallHeader>
        <SmallText>
          {shippingAddress.firstName + ' ' + shippingAddress.lastName}
        </SmallText>
        <SmallText>{shippingAddress.address}</SmallText>
        <SmallText>
          {shippingAddress.postalCode + ' ' + shippingAddress.city}
        </SmallText>
      </div>
      <div>
        <SmallHeader> {deliveryMethod}</SmallHeader>
        <SmallText css={{ mb: 8 }}>{shippingMethod}</SmallText>
        <SmallHeader>{paymentMethodLabel} </SmallHeader>
        <SmallText>
          {paymentMethods.map((p) => (
            <div>{p}</div>
          ))}
        </SmallText>
      </div>
      <PriceContainer>
        <Line>
          <SmallText>{amount}: </SmallText>
          <SmallText>{numberOfItems}</SmallText>
        </Line>
        <Line>
          <SmallText> {`${total}: `} </SmallText>
          <SmallText> {totalPriceForItems.priceRoundedAsString} </SmallText>
        </Line>
        {totalDiscount && !!!totalDiscount.isZero && (
          <DiscountLine>
            <SmallText>{totalDiscountText}: </SmallText>
            <SmallText>{totalDiscount.priceRoundedAsString}</SmallText>
          </DiscountLine>
        )}
        <Line>
          <SmallText> {shippingCost}: </SmallText>
          <SmallText> {totalFreightPrice.priceRoundedAsString} </SmallText>
        </Line>
        <Divider css={{ w: '100%' }} />
        <Line>
          <SmallText> {`${total}: `} </SmallText>
          <SmallText> {totalPrice.priceAsString} </SmallText>
        </Line>
      </PriceContainer>
    </Info>
  );
}

const Info = styled('div', {
  mt: 6,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr ',
  fw: '$fw300',
  color: '$grey900',
  w: '100%',
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

const Line = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const DiscountLine = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  color: '$JE68GreenPrimary',
});

const PriceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gridColumnStart: 'span 2',
  '@mediaMinLarge': {
    gridColumnStart: 'initial',
  },
});

const SmallHeader = styled('h5', {
  fs: 6,
  textTransform: 'uppercase',
  mb: 2,
});

const SmallText = styled('p', {
  fs: 6,
  mb: 2,
  textTransform: 'capitalize',
});

export default OrderHistoryDetails;
