import Button from 'Atoms/Buttons/Button';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import AccountPageModel from 'Models/Pages/AccountPage/AccountPageModel.interface';
import { useEffect, useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import AddMotorcycleModal, {
  SelectedType,
} from 'Organisms/Modal/AddMotorcycleModal';
import {
  AddBike,
  GetBikes,
  RemoveBike,
  SetPrimaryBike,
} from 'Shared/MyGarage/MyGarage';
import MyGarageBikeModel from 'Models/MyGarage/MyGarageBikeModel.interface';
import SingleOptionCard from 'Organisms/SingleOptionCard/SingleOptionCard';
import { styled } from 'stitches.config';
import { MotorcycleIcon } from 'Atoms/Icons';
import KexLink from 'Kex/KexLink';
import { StyledDivider } from 'Pages/AccountPage/AccountPage';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import { updateUserState } from 'Shared/UserContextProvider/useQueryUserState';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { ENUM_KTM } from 'Shared/Constants/brand';

const AccountGarageTab = () => {
  const {
    myGarage: { categories, bodyText },
  } = useCurrentPage<AccountPageModel>();
  const {
    commonLabels: { addItem, removeItem, cancel },
    myGarageLabels: { myMotorcycle },
    motorcycleModelFinderLabels: { buttonText },
    explodedViews,
  } = useTranslationData();
  const {
    user: { primaryBike },
  } = useUserStateData();
  const { staticPages } = useAppSettingsData();

  const kexNavigate = useKexNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [bikes, setBikes] = useState<MyGarageBikeModel[]>([]);
  const [primaryBikeId, setPrimaryBikeId] = useState<string>(
    primaryBike?.id || ''
  );

  const [removeBikeId, setRemoveBikeId] = useState<string>(primaryBikeId);
  const [isConfirmDeleteView, setIsConfirmDeleteView] =
    useState<boolean>(false);

  async function handleGetBikes() {
    const res = await GetBikes();
    setBikes(res.models);
  }

  useEffect(() => {
    let hasMounted = true;

    if (hasMounted) {
      handleGetBikes();
    }

    return () => {
      hasMounted = false;
    };
  }, []);

  const setPrimaryBike = async (bikeId: string) => {
    const res = await SetPrimaryBike(bikeId);
    setPrimaryBikeId(res.bike.id);
  };

  const addBike = async (selected: SelectedType) => {
    const res = await AddBike(selected.brand, selected.year, selected.model);

    setBikes(res.models);

    // set default primary bike when adding the first one
    if (res.models.length === 1 || primaryBikeId === '') {
      const defaultPrimaryBike = res.models[0].id;
      setPrimaryBike(defaultPrimaryBike);
    }

    setShowModal(false);
  };

  const removeBike = async () => {
    const res = await RemoveBike(removeBikeId);

    setBikes(res.models);

    // default to one primary bike after deleting
    if (res.models.length !== 0 && removeBikeId === primaryBikeId) {
      const defaultPrimaryBike = res.models[res.models.length - 1].id;
      setPrimaryBike(defaultPrimaryBike);
    }

    setIsConfirmDeleteView(false);

    updateUserState();
  };

  const confirmDelete = async () => {
    setIsConfirmDeleteView(true);

    setRemoveBikeId(primaryBikeId);
  };

  const redirect = async (href: string) => {
    // Need to retrieve updated state for primary bike
    await updateUserState();
    kexNavigate(href);
  };

  const hasBikes = bikes.length > 0;

  return (
    <>
      <Paragraph css={{ my: 8 }}>{bodyText}</Paragraph>
      {hasBikes && (
        <>
          <StyledDivider />

          <SectionHeader>{myMotorcycle}</SectionHeader>
        </>
      )}

      {bikes?.map((item, bikeIndex) => {
        return (
          <BikeItem key={item.id + bikeIndex}>
            <SingleOptionCard
              content={
                <BikeContent>
                  <MotorcycleIcon fillColor="primary" size="m" />
                  <BikeInnerContent>
                    <BikeName>{item.name}</BikeName>
                    <BikeCategories>
                      {item.id === primaryBikeId && (
                        <>
                          {categories.map((link, linkIndex) => (
                            <KexLink
                              key={linkIndex}
                              onClick={() => {
                                redirect(
                                  link.href + `?selectedModel=${item.modelId}`
                                );
                              }}
                              css={{ mb: 0, textDecoration: 'underline' }}
                              fontFamily="secondary400"
                              underlined={false}
                            >
                              {link.text}
                            </KexLink>
                          ))}
                          {item.brand === ENUM_KTM && (
                            <KexLink
                              onClick={() => {
                                redirect(staticPages.explodedViewsPage);
                              }}
                              css={{ mb: 0, textDecoration: 'underline' }}
                              fontFamily="secondary400"
                              underlined={false}
                            >
                              {explodedViews.explodedViewsLink}
                            </KexLink>
                          )}
                        </>
                      )}
                    </BikeCategories>
                  </BikeInnerContent>
                </BikeContent>
              }
              isActive={item.id === primaryBikeId && !isConfirmDeleteView}
              isError={
                item.id === (removeBikeId || primaryBikeId) &&
                isConfirmDeleteView
              }
              onClick={async () => {
                if (isConfirmDeleteView) {
                  setRemoveBikeId(item.id);
                  return;
                }

                setPrimaryBike(item.id);
              }}
            />
          </BikeItem>
        );
      })}

      <MyGarageCTA>
        {isConfirmDeleteView ? (
          <>
            <Button type="secondary" onClick={removeBike}>
              {removeItem}
            </Button>
            <Button
              onClick={() => setIsConfirmDeleteView(false)}
              type="tertiary"
            >
              {cancel}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => setShowModal(true)}>{addItem}</Button>
            {hasBikes && (
              <Button onClick={confirmDelete} type="secondaryDark">
                {removeItem}
              </Button>
            )}
          </>
        )}
      </MyGarageCTA>

      <AddMotorcycleModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleAdd={addBike}
        buttonText={buttonText}
      />
    </>
  );
};

const SectionHeader = styled('span', {
  fontFamily: '$fontSecondary400',
  fs: 9,
  lineHeight: '$lh155',
  mb: 8,
  display: 'block',
});

const BikeItem = styled('div', {
  mb: 4,
});

const BikeContent = styled('div', {
  backgroundColor: '$primary4',
  display: 'flex',
  gap: 16,
  width: '100%',
  p: 4,
  minHeight: 88,
});

const BikeInnerContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const BikeCategories = styled('div', {
  display: 'flex',
  gap: 24,
  flexWrap: 'wrap',
});

const BikeName = styled('span', {
  fontFamily: '$fontSecondary600',
  fs: 10,
  lineHeight: '$lh12',
  mb: 3,
});

const MyGarageCTA = styled('div', {
  mt: 8,
  display: 'flex',
  gap: 16,
});

export default AccountGarageTab;
