import Dots from 'Atoms/Loaders/Dots';
import React from 'react';
import { styled } from 'stitches.config';

type PropTypes = {
  children: React.ReactNode;
  isLoading: boolean;
  headers: string[];
};

function OrderTable({ children, isLoading, headers }: PropTypes) {
  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Dots />
    </div>
  ) : (
    <TableWraper>
      <Table>
        <tbody>
          <Row>
            {headers.map((value, index) => {
              return (
                <Header key={value + index}>
                  <Spacing>{value}</Spacing>
                </Header>
              );
            })}
          </Row>

          {children}
        </tbody>
      </Table>
    </TableWraper>
  );
}

const Spacing = styled('div', {});

const Row = styled('tr', {
  backgroundColor: '$primary4',
});

const Header = styled('th', {
  textAlign: 'left',
  width: '30%',
  textTransform: 'uppercase',
  fs: 6,
  p: 4,
});

const Table = styled('table', {
  width: '100%',
});

const TableWraper = styled('div', {
  width: '100%',
});

export default OrderTable;
